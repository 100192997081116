import React, { useState, useContext } from "react";
import { Trans } from "react-i18next";

import { DonationStateContext } from "../DonationStateProvider";
import * as UI from "./ui";
import Form from "./Form";

const PromoCodeForm = () => {
  const [ editing, setEditing ] = useState(false);
  const { promoCode, setPromoCode } = useContext(DonationStateContext);

  if(editing) {
    return <Form promoCode={promoCode} setPromoCode={setPromoCode} setEditing={setEditing} />;
  }

  if(promoCode?.name) {
    return (
      <UI.AppliedPromoCode>
        <Trans i18nKey="promoCodeControls.applied" values={{ name: promoCode.name }}>
          Promo Code <strong>{{name}}</strong> has been applied! <UI.ChangePromoCode onClick={() => setEditing(true)}>Change</UI.ChangePromoCode>
        </Trans>
      </UI.AppliedPromoCode>
    );
  }

  return (
    <UI.ApplyPromoCode onClick={() => setEditing(true)}>
      <Trans i18nKey="promoCodeControls.apply">
        Have a promo code? <em>Apply it here</em>.
      </Trans>
    </UI.ApplyPromoCode>
  );
};


export default PromoCodeForm;
