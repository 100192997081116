import React, { useState } from "react";
import { any, complement, equals, isEmpty, omit, pipe, prop, values } from "ramda";
export const DonationStateContext = React.createContext();

export const isDonorIncomplete = pipe(
  omit(["addressLine2"]),
  values,
  any(isEmpty),
);

export const isPaymentPending = pipe(
  prop("status"),
  complement(equals("succeeded")),
)

const DonationStateProvider = ({ children, clearState, ...rest }) => {
  const [ donor, setDonor ] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "US",
    email: "",
    phoneNumber: "",
  });

  const [ donationLevel, setDonationLevel ] = useState({});
  const [ promoCode, setPromoCode] = useState(rest.promoCode || {});
  const [ paymentIntent, setPaymentIntent ] = useState({});

  const contextValue = {
    donor,
    setDonor,
    promoCode,
    setPromoCode,
    donationLevel,
    setDonationLevel,
    paymentIntent,
    setPaymentIntent,
    clearState,
  };

  return (
    <DonationStateContext.Provider value={contextValue}>
      {children}
    </DonationStateContext.Provider>
  )
}

export default DonationStateProvider;

