import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import App from './App'
import CssReset from "./lib/CssReset";
import PlatformAnalytics from "./components/PlatformAnalytics";

import "./i18n";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  }),
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <PlatformAnalytics />
    <CssReset />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.Fragment>,
)
