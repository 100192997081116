import styled from "styled-components";

export const AppliedPromoCode = styled.div`
  font-size: 0.875em;
  color: ${props => props.theme.textColor};
  text-align: center;
  margin-bottom: 10px;

  strong {
    font-weight: bold;
  }
`;

export const ChangePromoCode = styled.a`
  font-size: 0.875em;
  text-decoration: underline;
  cursor: pointer;
`;

export const ApplyPromoCode = styled.div`
  color: ${props => props.theme.textColor};
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;

  strong {
    font-weight: bold;
  }

  em {
    text-decoration: underline;
  }
`;

export const Form = styled.form`
  margin: 0 3px 10px;
`

export const Error = styled.div`
  color: #db3a34;
  font-size: 0.75em;
  text-align: center;
  display: ${props => props.showError ? 'unset' : 'none'};
`;
