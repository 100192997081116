import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import PromoCodeForm from "../PromoCodeForm";
import { DonationStateContext } from "../DonationStateProvider";
import * as UI from "./ui";
import DonationLevel from "../DonationLevel";
import Footer from "../../Footer";

const DonationLevels = ({ sweepstakes, onComplete }) => {
  const { t } = useTranslation();
  const { donationLevel: selected, promoCode, setDonationLevel } = useContext(DonationStateContext);

  const handleClick = donationLevel => {
    setDonationLevel(donationLevel);
    onComplete();
  };

  return (
    <React.Fragment>
      <UI.PageLayout>
        <UI.DonationLevelsLayout>
          {sweepstakes.donationLevels.map(donationLevel => (
            <UI.DonationLevelItem key={donationLevel.id}>
              <DonationLevel donationLevel={donationLevel} promoCode={promoCode} onClick={handleClick} selected={selected.id === donationLevel.id} />
            </UI.DonationLevelItem>
          ))}
        </UI.DonationLevelsLayout>
        <PromoCodeForm />
        <UI.AmoeLink href={`${sweepstakes.donationAppUrl}/amoe`} target="_blank" rel="noopener noreferrer">
          {t("donationLevelList.legal")}
        </UI.AmoeLink>
      </UI.PageLayout>
      <Footer />
    </React.Fragment>
  );
}

export default DonationLevels;
