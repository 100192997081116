import styled from "styled-components";

export const Layout = styled.div`
  padding: 10px 0;
  margin-bottom: 20px;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 10px;
  color: ${props => props.theme.textColor};
`;

export const Address = styled.div`
  font-size: 0.75em;
  margin-bottom: 10px;
  color: ${props => props.theme.textColor};
  opacity: 0.8;
`;

export const Contact = styled.div`
  font-weight: 700;
  font-size: 0.875em;
  color: ${props => props.theme.textColor};
`;
