import styled from "styled-components";

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5px;
`;

export const DonationLevelsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const DonationLevelItem = styled.div`
  @media (min-width: 501px) {
    min-width: 25%;
    padding-right: 10px;

    &:nth-child(4n) {
      padding-right: 0;
    }

    &:nth-child(-n + 4) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 500px) {
    min-width: 50%;
    padding-right: 10px;

    &:nth-child(2n) {
      padding-right: 0;
    }

    &:nth-child(-n + 6) {
      padding-bottom: 10px;
    }
  }
`;

export const AmoeLink = styled.a`
  color: ${props => props.theme.textColor};
  font-size: 0.825rem;
  opacity: 0.75;
`;
