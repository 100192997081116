import React from 'react';
import { useTranslation } from 'react-i18next';

import Money from "./Money"
import * as UI from "./ui";

const DonationLevel = ({ donationLevel, promoCode, onClick, selected }) => {
  const { t } = useTranslation();
  const { rewardTickets, price } = donationLevel;

  const ticketString = t('donationLevel.ticket', { count: rewardTickets });

  const handleClick = onClick && (() => {
    onClick(donationLevel);
  })

  const bonusPercent = (promoCode && promoCode.bonusPercent) || 0;
  const promoTickets = Math.ceil((rewardTickets * bonusPercent) / 100)
  const promoTicketString = promoTickets && t('donationLevel.promo', { count: promoTickets });

  return (
    <UI.Layout onClick={handleClick} selected={selected} level={donationLevel.level}>
      <UI.Price>
        <Money cents={price} />
      </UI.Price>
      <UI.Reward>{ticketString}</UI.Reward>
      <UI.Promo applied={promoTickets}>{promoTicketString}</UI.Promo>
    </UI.Layout>
  );
}

export default DonationLevel;
