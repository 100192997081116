import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from "ramda";
import { ThemeProvider } from "styled-components";
import { mergeRight } from "ramda";

import postMessage from "./postMessage";

export const BootParameterContext = React.createContext();

const useParentMessageConfiguration = () => {
  const [messageData, setMessageData] = useState({});

  useEffect(() => {
    const callback = e => {
      if (document.referrer.includes(e.origin)) {
        setMessageData(data => mergeRight(data, e.data));
      }
    };

    window.addEventListener('message', callback, false);
    postMessage("ready");

    return () => {
      window.removeEventListener('message', callback, false);
    };
  }, []);

  return messageData;
};

const useColorConfig = ({ name, defaultColor }, params) => {
  const color = params.get(name);
  const matches = color && color.match(/#(?:[0-9A-F]{3}){1,2}/i);

  if(matches && matches[0]) {
    return matches[0];
  }

  return defaultColor;
}

const value = {};

const theme = {
  primaryColorHover: "linear-gradient(rgb(0, 0, 0, 0.2) 0 0)",
};

const BASENAME_REGEX = /^\/(?<id>[A-Za-z\d-]*)/;
const NULL_MATCH = { groups: {} };

const BootParameterProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const match = window.location.pathname.match(BASENAME_REGEX) || NULL_MATCH;
  const { id } = match.groups;

  if (isNil(id) || isEmpty(id)) {
    return null;
  }

  const params = new URLSearchParams(window.location.search.slice(1));
  const locale = params.get('locale');

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  const { promo } = useParentMessageConfiguration();

  value.id = id;
  value.preview = params.get('preview');

  if (!value.promo) {
    value.promo = promo;
  }

  if(!theme.primaryColor) {
    theme.primaryColor = useColorConfig({ name: 'primaryColor', defaultColor: '#00C19D' }, params);
  };

  if(!theme.textColor) {
    theme.textColor = useColorConfig({ name: 'textColor', defaultColor: '#333333' }, params);
  };

  if(!theme.mode) {
    const displayMode = params.get('displayMode');
    theme.displayMode = displayMode || "light";
  };

  return (
    <BootParameterContext.Provider value={value} >
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </BootParameterContext.Provider>
  );
};

export default BootParameterProvider;
