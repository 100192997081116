import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { DonationStateContext } from "../DonationStateProvider";
import countries from "../../../lib/countries";

import CountdownTimer from "../../CountdownTimer";
import { Field, Select, FieldGroup, Submit } from "../../../lib/forms";
import { setupPaymentIntentMutation } from "./gql";
import * as UI from "./ui";

const DonorForm = ({ onComplete }) => {
  const { t } = useTranslation();
  const { donationLevel, promoCode, donor, setDonor, paymentIntent, setPaymentIntent } = useContext(DonationStateContext);
  const [ setupPaymentIntent, { loading }] = useMutation(setupPaymentIntentMutation);

  const setDonorField = field => e => {
    setDonor({ ...donor, [field]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    setupPaymentIntent({
      variables: {
        attributes: {
          id: paymentIntent && paymentIntent.id,
          donationLevelId: donationLevel.id,
          promoCodeName: promoCode && promoCode.name,
        }
      }
    }).then(({data: { setupPaymentIntent: { paymentIntent }}}) => {
      if(paymentIntent) {
        setPaymentIntent(paymentIntent);
        onComplete();
      } else {
        alert(t("donorForm.error"));
      }
    });
  };

  return (
    <React.Fragment>
      <UI.BackLink to="/">← {t("donorForm.back")}</UI.BackLink>
      <UI.Form onSubmit={handleSubmit}>
        <FieldGroup>
          <Field id="firstName" label={t("donorForm.firstName")} onChange={setDonorField("firstName")} value={donor.firstName} required />
          <Field id="lastName" label={t("donorForm.lastName")} onChange={setDonorField("lastName")} value={donor.lastName} required />
        </FieldGroup>
        <Field type="email" id="email" label={t("donorForm.email")} onChange={setDonorField("email")} value={donor.email} required />
        <Field type="tel" id="phoneNumber" label={t("donorForm.phone")} onChange={setDonorField("phoneNumber")} value={donor.phoneNumber} required />
        <FieldGroup>
          <Field id="addressLine1" label={t("donorForm.line1")} onChange={setDonorField("addressLine1")} value={donor.addressLine1} weight={3} required />
          <Field id="addressLine2" label={t("donorForm.line2")} onChange={setDonorField("addressLine2")} value={donor.addressLine2} weight={2} />
        </FieldGroup>
        <FieldGroup>
          <Field id="city" label={t("donorForm.city")} onChange={setDonorField("city")} value={donor.city} weight={2} required />
          <Field id="state" label={t("donorForm.state")} onChange={setDonorField("state")} value={donor.state} required />
          <Field id="zipCode" label={t("donorForm.zipCode")} onChange={setDonorField("zipCode")} value={donor.zipCode} required />
        </FieldGroup>
        <Select id="country" label={t("donorForm.country")} onChange={setDonorField("country")} value={donor.country} options={countries} required />
        <CountdownTimer>
          <Submit value={t("donorForm.submit")} disabled={loading} />
        </CountdownTimer>
      </UI.Form>
    </React.Fragment>
  );
};

export default DonorForm;
