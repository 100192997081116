import { toMoneyString } from "../DonationLevel/Money";
import postMessage from "../../../lib/postMessage";

const trackSuccessWithGTag = ({ paymentIntent, donationLevel, promoCode }) => {
  window?.gtag('event', 'purchase', {
    event_category: 'sweepstakes',
    event_label: 'donation',
    currency: paymentIntent.currency,
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    transaction_id: paymentIntent.id,
    coupon: promoCode && promoCode.name,
    items: [
      {
        item_id: donationLevel.id,
        item_name: `${toMoneyString({ cents: paymentIntent.amount })} Donation`,
      }
    ],
  });
};

const postDonationToParent = ({ paymentIntent, donationLevel, promoCode }) => {
  postMessage("donation", {
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    currency: paymentIntent.currency,
    transactionId: paymentIntent.id,
    donationLevelId: donationLevel.id,
    coupon: promoCode && promoCode.name,
  });
};

export const trackSuccess = ({ paymentIntent, donationLevel, promoCode }) => {
  trackSuccessWithGTag({ paymentIntent, donationLevel, promoCode });
  postDonationToParent({ paymentIntent, donationLevel, promoCode });
};
