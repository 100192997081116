import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import * as UI from "./ui";
import { trackSuccess } from "./utils";
import { DonationStateContext } from "../DonationStateProvider";
import { Submit } from "../../../lib/forms";
import DonationCard from "./DonationCard";
import CountdownTimer from "../../CountdownTimer";
import Footer from "../../Footer";

const STRIPE_CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
    }
  }
};

const PaymentStep = ({ onComplete }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { donationLevel, donor, promoCode, paymentIntent, setPaymentIntent } = useContext(DonationStateContext);
  const [ processing, setProcessing ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    setProcessing(true);

    const options = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${donor.lastName}, ${donor.firstName}`,
          address: {
            line1: donor.addressLine1,
            line2: donor.addressLine2,
            city: donor.city,
            state: donor.state,
            postal_code: donor.zipCode,
            country: donor.country,
          },
          email: donor.email,
          phone: donor.phoneNumber,
        },
      }
    };

    stripe.confirmCardPayment(paymentIntent.clientSecret, options).then(result => {
      if(result.error) {
        setErrorMessage(result.error.message);
        setProcessing(false);
      } else {
        setPaymentIntent(result.paymentIntent);
        trackSuccess({ paymentIntent: result.paymentIntent, donationLevel, promoCode });
        onComplete();
      }
    });
  };

  return (
    <React.Fragment>
      <UI.BackLink to="/donor">← {t("paymentForm.back")}</UI.BackLink>
      <DonationCard donationLevel={donationLevel} promoCode={promoCode} donor={donor} />
      { errorMessage && <UI.ErrorMessage>{errorMessage}</UI.ErrorMessage> }
      <UI.Form onSubmit={onSubmit}>
        <UI.StripeElementLayout>
          <UI.StripeElement>
            <CardElement options={STRIPE_CARD_ELEMENT_OPTIONS} />
          </UI.StripeElement>
        </UI.StripeElementLayout>
        <UI.Legal>{t("paymentForm.legalNotice")}</UI.Legal>
        <CountdownTimer>
          <Submit value={processing ? t("paymentForm.loading") : t("paymentForm.submit")} disabled={processing} />
        </CountdownTimer>
      </UI.Form>
      <Footer />
    </React.Fragment>
  )
};

export default PaymentStep;
