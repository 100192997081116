import styled from "styled-components";

export const Layout = styled.div`
`;

export const Heading = styled.h1`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

export const Message = styled.p`
margin-bottom: 10px;
line-height: 1.125em;
`;

export const DonateAgain = styled.div`
  margin: 20px 0 40px;
`;
