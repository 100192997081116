import { useEffect } from "react";
import { isNil } from "ramda";
import { Helmet } from "react-helmet";

// https://developers.google.com/tag-platform/gtagjs/install#create_your_own_tag
const PlatformAnalytics = () => {
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID;

  if (isNil(googleAnalyticsId)) { return null; }

  useEffect(() => {
    window.gtag('config', googleAnalyticsId)
  }, [googleAnalyticsId]);

  return (
    <Helmet>
      <script key={googleAnalyticsId} async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}></script>
    </Helmet>
  );
};

export default PlatformAnalytics;
