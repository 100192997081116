import styled from "styled-components"

export const Layout = styled.div`
  text-align: center;
`

export const Banner = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.primaryColor};
  color: #FFFFFF;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25em;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  flex: 1;
  min-width: 100%;

  line-height: 1.5em;
  padding: 20px;

  @media screen and (min-width: 440px) and (max-width: 768px) {
    min-width: auto;
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  color: ${props => props.theme.textColor};
`;

export const Value = styled.div`
  font-weight: 700;
  font-size: 1.35em;
  color: ${props => props.theme.textColor};
`;

export const Location = styled.div`
  font-size: 0.875em;
  color: ${props => props.theme.textColor};
`;

