import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime, Duration } from "luxon";

import * as UI from "./ui";

const Timer = ({ endsAt: rawEndsAt }) => {
  const { t } = useTranslation();
  const endsAt = DateTime.fromISO(rawEndsAt);
  const [timeLeft, setTimeLeft] = useState(endsAt.diffNow());
  const timeout = useRef(null);

  const tick = () => {
    const diffNow = endsAt.diffNow();

    if (diffNow <= 0) {
      setTimeLeft(Duration.fromMillis(0));
      return;
    }

    setTimeLeft(endsAt.diffNow());
    timeout.current = setTimeout(tick, 1000);
    return () => clearTimeout(timeout.current);
  }

  useEffect(tick, []);

  return (
    <UI.Timer>
      {timeLeft.toFormat("mm:ss")} {t("countdown.descriptor")}
    </UI.Timer>
  );
}

export default Timer;
