import { checkSweepstakesExists } from "./gql";
import useSweepstakesQuery from "../lib/useSweepstakesQuery";

const SweepstakesExistsGuard = ({ children }) => {
  const { loading, data, error } = useSweepstakesQuery(checkSweepstakesExists)

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  if(sweepstakes) {
    return children;
  }

  return null;
};


export default SweepstakesExistsGuard;
