import React, { useContext, useState } from "react";

import { ScheduleSwitch } from "../../lib/schedule";
import { BootParameterContext } from "../../lib/BootParameterProvider";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { sweepstakesQuery } from "./gql";

const SweepstakesSwitch = ({ Open, Closed }) => {
  const [donationInProgress, setDonationInProgress] = useState(false);
  const { promo } = useContext(BootParameterContext);
  const { data, loading, error } = useSweepstakesQuery(sweepstakesQuery, {
    variables: {
      promoCodeName: promo,
    },
  });

  if(loading || error) {
    return null;
  }

  const {
    sweepstakes,
    promoCode,
  } = data;

  const onDonationStart = () => {
    setDonationInProgress(true)
  };

  const openProps = {
    sweepstakes,
    promoCode,
    onDonationStart,
  };

  return (
    <ScheduleSwitch>
      {donationInProgress && <Open periods={["grace"]} {...openProps} />}
      <Open periods={["review", "open", "closing"]} {...openProps} />
      <Closed periods={["grace", "closed", "drawn", "archived"]} sweepstakes={sweepstakes} />
    </ScheduleSwitch>
  )
};

export default SweepstakesSwitch;
