import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useLazySweepstakesQuery from "../../../lib/useLazySweepstakesQuery";
import { Field, FieldGroup, Submit } from "../../../lib/forms";
import { getPromoCodeQuery } from "./gql";
import * as UI from "./ui";

const Form = ({ setPromoCode, setEditing }) => {
  const { t } = useTranslation();
  const [ input, setInput ] = useState("");
  const [ getPromoCode, { data, loading, called } ] = useLazySweepstakesQuery(getPromoCodeQuery);

  const handleSubmit = e => {
    e.preventDefault();

    getPromoCode({ variables: { name: input }}).then(({ data }) => {
      if(data.promoCode) {
        setPromoCode(data.promoCode);
        setEditing(false);
      }
    })
  }

  const handleChange = e => {
    const val = e.target.value;
    const cleaned = val.replace(/[^A-Za-z0-9-_]+/g, "");

    setInput(cleaned);
  }

  const showError = called && data?.promoCode === null;

  return (
    <UI.Form onSubmit={handleSubmit}>
      <FieldGroup>
        <Field id="promoCode" label={t("promoCodeForm.default")} value={input} onChange={handleChange} />
        <Submit value={t("promoCodeForm.submit")} disabled={loading} />
      </FieldGroup>
      <UI.Error showError={showError}>{t("promoCodeForm.error")}</UI.Error>
    </UI.Form>
  );
};

export default Form;
