import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { DonationStateContext } from "../DonationStateProvider";
import { Submit } from "../../../lib/forms";
import Footer from "../../Footer";
import * as UI from "./ui";

const ThankYou = ({ onComplete, nonprofit }) => {
  const { t } = useTranslation();
  const { clearState } = useContext(DonationStateContext);

  const onClick = e => {
    e.preventDefault();

    clearState();
    onComplete();
  }

  return (
    <React.Fragment>
      <UI.Layout>
        <UI.Heading>{t("thankYou.heading")}</UI.Heading>
        <UI.Message>
          {t("thankYou.message1", { name: nonprofit.name })}
        </UI.Message>
        <UI.Message>
          {t("thankYou.message2")}
        </UI.Message>
        <UI.DonateAgain>
          <Submit onClick={onClick} value={t("thankYou.cta")} />
        </UI.DonateAgain>
      </UI.Layout>
      <Footer />
    </React.Fragment>
  )
};

export default ThankYou;
