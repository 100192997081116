import styled, { keyframes } from "styled-components";

export const Price = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const Reward = styled.div`
  white-space: pre-wrap;
  font-size: 0.825em;
  font-weight: 400;
  line-height: 1.25em;
`;

export const Promo = styled.div`
  white-space: pre-wrap;
  font-size: 0.825em;
  font-weight: bold;
  line-height: 1.25em;

  opacity: ${props => props.applied ? 1 : 0};
  max-height: ${props => props.applied ? "1.25em" : "0"};
  transform-origin: top left;
  transition: max-height 250ms ease-in-out;
  overflow: hidden;
`;

const shine = keyframes`
  0% {
  }
  100% {
    transform: translate(100%, 100%);
  }
`;

export const Layout = styled.div`
  position: relative;
  background-color: ${props => props.theme.primaryColor};
  border-radius: 2px;
  padding: 15px;
  color: #ffffff;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    transform: translate(-100%, -100%);
    background-image: linear-gradient(to left top, transparent 10%, rgba(255,255,255,0.2) 50%, transparent 90%);
    animation: 250ms ${shine} ease-out forwards;
    animation-delay: calc(500ms + (50ms * ${props => props.level}));
  }

  &:hover {
    background-image: ${props => props.theme.primaryColorHover};
  }

  ${props => props.selected && `
    background-image: ${props => props.theme.primaryColorHover};
  `}
`;

