import React from "react";

import { useSchedulePeriod } from "../../lib/schedule";

import * as UI from "./ui";
import Timer from "./Timer";

const CountdownTimer = ({ children }) => {
  const { name, period } = useSchedulePeriod();

  if (name !== "grace") {
    return children;
  }

  return (
    <UI.Layout>
      {children}
      <Timer endsAt={period[1]} />
    </UI.Layout>
  );
}

export default CountdownTimer;
