import { gql } from "@apollo/client";

export const winnerQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id

      prize {
        id
        winnerSnapshot {
          name
          location
          number
          drawnAt
        }
      }
    }
  }
`;
