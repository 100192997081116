import { gql } from "@apollo/client";

export const sweepstakesQuery = gql`
  query SweepstakesQuery($id: SweepstakesSlug!, $promoCodeName: String) {
    sweepstakes(id: $id) {
      id
      donationAppUrl
      location
      winningTicketDrawnAt

      nonprofit {
        name
        paymentAccountId
      }

      donationLevels {
        id
        price
        level
        rewardTickets
      }
    }

    promoCode(id: $id, name: $promoCodeName) {
      id
      name
      bonusPercent
    }
  }
`;

