import React from "react";

import DonorCard from "../DonorCard";
import DonationLevel from "../DonationLevel";

const DonationCard = ({ donationLevel, donor,  promoCode }) => (
  <div>
    <DonationLevel donationLevel={donationLevel} promoCode={promoCode} selected />
    <DonorCard donor={donor} />
  </div>
);

export default DonationCard;
