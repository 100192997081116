import React from 'react';

import { ScheduleSwitch } from "../../lib/schedule";
import ClosedNotice from "./ClosedNotice";
import DrawnNotice from "./DrawnNotice";
import Footer from "../Footer";

const ClosedContentPage = ({ sweepstakes }) => (
  <React.Fragment>
    <ScheduleSwitch>
      <ClosedNotice periods={["grace", "closed"]} sweepstakes={sweepstakes} />
      <DrawnNotice periods="drawn" sweepstakes={sweepstakes} />
    </ScheduleSwitch>
    <Footer />
  </React.Fragment>
);

export default ClosedContentPage;
