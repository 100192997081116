import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from "luxon";
import DrawingLocation from "./DrawingLocation";

import * as UI from "./ui";

const DrawingClosedAside = ({ sweepstakes }) => {
  const { t } = useTranslation();
  const winningTicketDrawnAt = DateTime.fromISO(sweepstakes.winningTicketDrawnAt, { zone: sweepstakes.timezone });

  return (
    <UI.Layout>
      <UI.Banner>
        <div>{t("closedNotice.heading")}</div>
      </UI.Banner>
      <UI.Info>
        <UI.Section>
          <UI.Label>{t("closedNotice.date")}</UI.Label>
          <UI.Value>{winningTicketDrawnAt.toLocaleString(DateTime.DATE_MED)}</UI.Value>
        </UI.Section>
        <UI.Section>
          <UI.Label>{t("closedNotice.time")}</UI.Label>
          <UI.Value>{winningTicketDrawnAt.toFormat("t ZZZZ")}</UI.Value>
        </UI.Section>
        <UI.Section>
          <UI.Label>{t("closedNotice.location")}</UI.Label>
          <UI.Location>
            <DrawingLocation location={sweepstakes.location}/>
          </UI.Location>
        </UI.Section>
      </UI.Info>
    </UI.Layout>
  );
};

export default DrawingClosedAside;
