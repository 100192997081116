import styled from "styled-components";
import { Link } from "react-router-dom";

export const Form = styled.form`
  margin: 0 3px;
`

export const BackLink = styled(Link)`
  display: block;
  color: ${props => props.theme.textColor};
  font-size: 0.75em;
  margin-bottom: 10px;
`;
