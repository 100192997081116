import React from "react";
import { useTranslation } from "react-i18next";

import Logo from "../Logo";
import useSweepstakesQuery from "../../lib/useSweepstakesQuery";
import { footerQuery } from "./gql"
import * as UI from "./ui";

const Footer = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useSweepstakesQuery(footerQuery);

  if(loading) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <UI.Footer>
      <UI.Main>
        <UI.Menu>
          <UI.FooterLink href={`${sweepstakes.donationAppUrl}/rules`} target="_blank" rel="noopener noreferrer">
            {t("footer.rules")}
          </UI.FooterLink>
          <UI.FooterLink href={`${sweepstakes.donationAppUrl}/faq`} target="_blank" rel="noopener noreferrer">
            {t("footer.faq")}
          </UI.FooterLink>
        </UI.Menu>
        <a href="https://www.tapkat.com/" target="_blank" rel="noopener noreferrer">
          <Logo />
        </a>
      </UI.Main>
      <UI.Legal>
        © {t("footer.legal1")}<br />{t("footer.legal2")}
      </UI.Legal>
    </UI.Footer>
  );
}

export default Footer;
