import styled from "styled-components";

export const Layout = styled.div`
  position: relative;
  display: flex;
`;

export const Timer = styled.div`
  padding: 5px 10px;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  text-align: center;
  align-self: start;
  background-color: ${props => props.theme.primaryColor};
  background-image: ${props => props.theme.primaryColorHover};
  margin-left: 4px;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: -8px;
    right: 3px;
  }
`;
