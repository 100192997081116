import { gql } from "@apollo/client";

export const footerQuery = gql`
  query FooterQuery($id: SweepstakesSlug!) {
    sweepstakes(id: $id) {
      id
      donationAppUrl
    }
  }
`;
