import React from 'react'
import { MemoryRouter } from 'react-router-dom';

import BootParameterProvider from "../lib/BootParameterProvider";
import { ScheduleProvider } from "../lib/schedule";
import SweepstakesExistsGuard from "./SweepstakesExistsGuard";
import SweepstakesHome from "../components/SweepstakesHome";

const App = () => (
  <MemoryRouter>
    <BootParameterProvider>
      <SweepstakesExistsGuard>
        <ScheduleProvider>
          <SweepstakesHome />
        </ScheduleProvider>
      </SweepstakesExistsGuard>
    </BootParameterProvider>
  </MemoryRouter>
);

export default App;
