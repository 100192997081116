import styled from "styled-components";
import { Link } from "react-router-dom";

export const Form = styled.form`
`

export const StripeElementLayout = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

export const StripeElement = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 12px 5px;
  background-color: #fff;
`;

export const ErrorMessage = styled.div`
  color: #fff;
  background-color: #db3a34;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.75em;
  border-radius: 2px;
`

export const Legal = styled.div`
  margin-bottom: 10px;
  font-size: 0.6875em;
  color: ${props => props.theme.textColor};
`;

export const BackLink = styled(Link)`
  display: block;
  color: ${props => props.theme.textColor};
  font-size: 0.75em;
  margin-bottom: 10px;
`;
