import styled from "styled-components";

export const Footer = styled.footer`
  padding-top: 5px;
  margin-top: 4px;
  color: ${props => props.theme.textColor};
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled.nav`
`;

export const FooterLink = styled.a`
  margin-right: 10px;
  font-size: 0.875em;
  color: ${props => props.theme.textColor};
`;

export const Legal = styled.div`
  font-size: 0.5625em;
  text-align: right;
  margin-right: 5px;
`;
