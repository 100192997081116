import React, { useContext, useMemo } from "react";
import styled from "styled-components";

import { BootParameterContext } from "../lib/BootParameterProvider";
import { ScheduleMask } from "../lib/schedule";
import SweepstakesSwitch from "./SweepstakesSwitch";
import DonationPage from "./DonationPage";
import ClosedContentPage from "./ClosedContentPage";

const defaultPeriods = ["open", "closing", "grace", "closed", "drawn", "archived"];

const Overlay = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  background-color: transparent;
  z-index: 1;
`;

const SweepstakesHome = () => {
  const { preview } = useContext(BootParameterContext);
  const enabledPeriods = useMemo(
    () => preview ? ["review"].concat(defaultPeriods) : defaultPeriods,
    [preview],
  );

  return (
    <ScheduleMask enabledPeriods={enabledPeriods}>
      <SweepstakesSwitch Open={DonationPage} Closed={ClosedContentPage} />
      {preview && <Overlay />}
    </ScheduleMask>
  )
}

export default SweepstakesHome;
