import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { v4 as uuidv4 } from 'uuid';

import DonationStateProvider from "./DonationStateProvider";
import DonationLevels from "./DonationLevels";
import DonorForm from "./DonorForm";
import Payment from "./Payment";
import ThankYou from "./ThankYou";

const DonationPage = ({ sweepstakes, promoCode, onDonationStart }) => {
  const { i18n } = useTranslation();
  const [stateKey, setStateKey] = useState(uuidv4());
  const navigate = useNavigate();

  const clearState = () => {
    setStateKey(uuidv4());
  };

  const stepComplete = nextPage => () => {
    navigate(nextPage);
  };
  const donationLevelSelected = () => {
    onDonationStart();
    navigate("donor");
  };

  const { nonprofit } = sweepstakes;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: nonprofit.paymentAccountId,
    locale: i18n.language,
  });

  return (
    <Elements stripe={stripePromise}>
      <DonationStateProvider key={stateKey} clearState={clearState} promoCode={promoCode}>
        <Routes>
          <Route path="/thank-you" element={<ThankYou onComplete={stepComplete("/")} nonprofit={nonprofit} />} />
          <Route path="/payment" element={<Payment onComplete={stepComplete("/thank-you")} />} />
          <Route path="/donor" element={<DonorForm onComplete={stepComplete("payment")} />} />
          <Route index element={<DonationLevels sweepstakes={sweepstakes} onComplete={donationLevelSelected} />} />
        </Routes>
      </DonationStateProvider>
    </Elements>
  );
};

export default DonationPage;
